<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="500"
  >
    <template v-slot:activator="{on}">
      <v-avatar class="overflow-hidden" size="100" v-on="on">
        <div v-if="value" :style="prevAvatarStyle" class="avatar" />
        <div v-else class="avatar d-flex align-center justify-center">
          <v-icon color="surface" size="64">
            {{ icons.avatar }}
          </v-icon>
        </div>
        <div class="avatar-hover d-flex align-center justify-center">
          <v-icon color="var(--v-surface-base)" large>
            {{ icons.edit }}
          </v-icon>
        </div>
      </v-avatar>
    </template>

    <v-card class="px-6">
      <v-form ref="form" enctype="multipart/form-data" @submit.prevent>
        <v-card-title>
          <h4 class="my-5 mx-auto">
            {{ $t('Avatar image') }}
          </h4>
        </v-card-title>
        <v-card-text class="text-center">
          <v-btn
            :disabled="saving"
            :ripple="false"
            class="mb-6"
            outlined
            rounded
            @click="openChooseFile"
          >
            {{ $t('Upload another image') }}
          </v-btn>
          <v-avatar
            :class="{dragover}"
            class="overflow-hidden dropzone"
            size="300"
            @dragover.prevent="dragover = true"
            @dragleave="dragover = false"
            @drop.stop.prevent="onDrop"
            @click="openChooseFile"
          >
            <div v-if="image" :style="avatarStyle" class="avatar" />
            <div v-else class="avatar d-flex align-center justify-center">
              <v-icon color="surface" size="192">
                {{ icons.avatar }}
              </v-icon>
            </div>
          </v-avatar>
          <input
            ref="fileInput"
            class="d-none"
            name="avatar"
            type="file"
            @change="onDrop"
          >
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                :disabled="saving"
                :ripple="false"
                class="mr-5 btn-light-border-gradient"
                color="primary"
                block
                rounded
                outlined
                @click="dialog = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="saving"
                :ripple="false"
                class="btn-bg-gradient"
                color="primary"
                elevation="0"
                block
                rounded
                @click="submit"
              >
                {{ $t('Save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mdiAccount, mdiPencilOutline} from '@mdi/js'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

export default {
  name: 'ModalAvatar',
  props: {
    value: {
      required: true,
      validator: prop => {
        return ['string', 'undefined'].includes(typeof prop) || prop === null
      }
    }
  },
  data () {
    return {
      dialog: false,
      dragover: false,
      file: null,
      image: null,
      saving: false,
      icons: {
        avatar: mdiAccount,
        edit: mdiPencilOutline
      }
    }
  },
  computed: {
    ...mapState({
      profileId: state => state.profile.id
    }),
    ...mapGetters({
      prevAvatarStyle: 'getAvatarStyle'
    }),
    avatarStyle () {
      return this.image
        ? {backgroundImage: `url("${this.image}")`}
        : {}
    }
  },
  created () {
    this.image = this.value
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show'
    }),
    ...mapActions({
      update: 'updateProfile'
    }),
    openChooseFile () {
      this.$refs.fileInput.click()
    },
    onDrop (e) {
      this.dragover = false
      let files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      this.file = files[0]
      this.createFile(files[0])
    },
    createFile (file) {
      if (!file.type.match('image.*')) {
        alert(this.$t('Allowed images only'))
        return
      }
      let reader = new FileReader()

      reader.onload = e => {
        let image = new Image()
        image.onload = ie => {
          this.image = this.resizeImage(image)
        }
        image.src = e.target.result
      }
      reader.readAsDataURL(file)
    },
    resizeImage (img) {
      let canvas = document.createElement('canvas')
      let size = 200
      let w = img.width
      let h = img.height
      let sw = w
      let sh = h
      let x = 0
      let y = 0
      if (w > h) {
        sw = h
        x = (w - sw) / 2
      } else {
        sh = w
        y = (h - sh) / 2
      }
      canvas.width = size
      canvas.height = size
      canvas.getContext('2d').drawImage(img, x, y, sw, sh, 0, 0, size, size)
      return canvas.toDataURL('image/jpeg')
    },
    async submit () {
      if (!this.file) return

      let data = new FormData(this.$refs.form.$el)
      data.set('avatar', this.file)

      this.saving = true
      try {
        await this.update(data)
        this.saving = false
        this.dialog = false
        this.showNotify({
          text: this.$t('Avatar image updated'),
          color: 'success'
        })
      } catch (e) {
        if (!this.handleError(e) && e.response.status === 422) {
          this.invalidFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-avatar {
    cursor: pointer;

    .avatar-hover {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s;
      background-color: var(--v-text-base);
      color: var(--v-surface-base);
    }

    &:hover .avatar-hover {
      opacity: 0.6;
    }
  }

  .drop-hint {
    display: none;
    color: var(--v-surface-base);
    font-size: 3rem;
  }

  .dropzone {
    cursor: pointer;
    border-style: solid;
    border-width: 3px;
    border-color: transparent;

    .change {
      display: none;
      background-color: #00000073;
      color: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding-bottom: .5rem;
    }

    &:hover .change {
      display: block;
    }

    &.dragover {
      background-color: red;
      .drop-hint {
        /*display: block;*/
      }

      .v-icon {
        /*display: none;*/
      }
    }
  }
</style>
