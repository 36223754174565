<template>
  <v-card class="pa-5" flat outlined>
    <v-row>
      <v-col>
        <h2 class="text-h2">
          {{ $t('Plan details') }}
        </h2>
      </v-col>
    </v-row>

    <div v-if="loading" class="mt-10 text-center">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-row v-else>
      <v-col
        v-for="plan in plans"
        :key="plan.id"
        class="d-flex align-stretch justify-start"
        cols="auto"
        xl="3"
        lg="4"
      >
        <v-card
          :class="{'bg-gradient': plan.id === currentPlanId || plan.id === activePlan.plan_id}"
          class="v-card-inner d-flex flex-column px-2 py-4"
          min-height="376"
          min-width="290"
          dark
          flat
        >
          <v-card-title>
            <div>
              <span class="text-capitalize">{{ plan.title }}</span>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="d-flex align-baseline">
              <div class="text-h3 text--primary mr-1">
                {{ plan.price === '' ? $t('Custom') : formatPrice(plan) }}
              </div>
              <div :class="{'transparent--text': plan.price === ''}">
                {{ $t('per_interval', {interval: plan.period_title}) }}
              </div>
            </div>
            <div class="mt-6">
              <div v-for="(str, i) in plan.description" :key="`desc-${plan.id}-${i}`" class="d-flex">
                <v-icon color="white">
                  {{ icons.check }}
                </v-icon>
                <div class="px-1">
                  {{ str }}
                </div>
                <div v-if="i === 0">
                  <v-chip
                    :class="[plan.id === currentPlanId || plan.id === activePlan.plan_id ? 'black' : 'bg-gradient']"
                    class="px-2"
                    small
                  >
                    {{ plan.deposit_limit }}
                  </v-chip>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-spacer />
          <v-card-actions class="justify-center">
            <v-btn
              v-if="plan.id === activePlan.plan_id"
              :disabled="unsubscribing || plan.id === null"
              :loading="unsubscribing"
              :ripple="false"
              block
              color="white"
              elevation="0"
              light
              rounded
            >
              {{ $t('Chosen tariff') }}
            </v-btn>
            <v-btn
              v-else-if="plan.id === currentPlanId"
              :ripple="false"
              block
              color="white"
              disabled
              elevation="0"
              light
              rounded
            >
              {{ $t('Inactive') }}
            </v-btn>
            <v-btn
              v-else
              :disabled="true || subscribing === plan.id || plan.id === null || (plan.id === null && profile.subscribed && profile.subscribed.id)"
              :loading="subscribing === plan.id"
              :ripple="false"
              class="btn-dark-border-gradient"
              elevation="0"
              block
              rounded
            >
              {{ profile.subscribed ? $t('Upgrade plan') : $t('Subscribe') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {mdiCheck} from '@mdi/js'
import AppDate from '@/components/AppDate'
import error401handler from '@/mixins/error-401-handler'

const datetimeRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/

export default {
  name: 'PaymentPlansBusiness',
  components: {AppDate},
  mixins: [error401handler],
  data () {
    return {
      loading: false,
      subscribing: false,
      unsubscribing: false,
      icons: {
        check: mdiCheck,
      },
    }
  },
  computed: {
    ...mapState({
      locale: state => state.config.locale,
      plans: state => state.subscriptionsBusiness.plans,
      profile: state => state.profile,
      currencies: state => state.config.currencies,
    }),
    ...mapGetters({
      defaultPaymentMethodId: 'subscriptionsBusiness/getDefaultPaymentMethodId',
      activePlan: 'getActivePlanBusiness',
    }),
    trialDaysLeftMessage () {
      let trialEndsDateParts = datetimeRegex.exec(this.activePlan.ends_at)
      let trialEnds = new Date(
        trialEndsDateParts[1] * 1,
        trialEndsDateParts[2] * 1 - 1,
        trialEndsDateParts[3] * 1,
        trialEndsDateParts[4] * 1,
        trialEndsDateParts[5] * 1,
        trialEndsDateParts[6] * 1,
      )

      let today = new Date()
      let daysLeft = (trialEnds - today) / (1000 * 86400)
      if (daysLeft > 1) {
        return this.$t('daysLeft', {count: Math.floor(daysLeft)})
      } else if (daysLeft > 0) {
        return this.$t('Less than a day left')
      }
      return this.$t('Trial expired')
    },
    currentPlanId () {
      return this.activePlan?.plan_id || this.profile.company.plan_id
    },
  },
  async mounted () {
    this.loading = true
    try {
      await this.loadPlans()
    } catch (e) {
      this.handleError(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      loadProfile: 'loadProfile',
      loadPlans: 'subscriptionsBusiness/ssLoadPlans',
      createSubscription: 'subscriptionsBusiness/ssCreateSubscription',
      cancelSubscription: 'subscriptionsBusiness/ssCancelSubscription',
      loadPaymentMethods: 'subscriptionsBusiness/ssLoadPaymentMethods',
    }),
    ...mapMutations({
      setError: 'error/set',
      showNotify: 'notify/show',
    }),
    formatPrice (plan) {
      let price = plan.price.toString().replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1 `)
      let currencySymbol = this.currencies[plan.currency].symbol
      return `${price} ${currencySymbol}`
    },
    async subscribe (planId) {
      this.subscribing = planId

      // First load fresh payment methods, to be sure we've got
      // the most recent default method, because there is a delay
      // in Stripe when assigning new card as "default".
      try {
        await this.loadPaymentMethods()
      } catch (e) {
        if (e.response?.data?.message !== 'messages.api.user.not_a_stripe_customer') {
          console.log('messages.api.user.not_a_stripe_customer', e.response.data)
        } else {
          this.handleError(e)
        }
      }

      if (!this.defaultPaymentMethodId) {
        this.subscribing = false
        this.showNotify({
          color: 'var(--v-primary-lighten1)',
          text: this.$t('PleaseProvideCreditCard'),
          timeout: 10000,
        })
        return this.$router.push({name: 'profile-add-payment-method'})
      }

      try {
        await this.createSubscription({
          planId,
          methodId: this.defaultPaymentMethodId,
        })
        await this.loadProfile()
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.setError({mode: 'modal', error: e, title: 'Error'})
        }
      } finally {
        this.subscribing = false
      }
    },
    async unsubscribe () {
      this.unsubscribing = true
      try {
        await this.cancelSubscription(this.profile.subscribed.id)
        await this.loadProfile()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.unsubscribing = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
