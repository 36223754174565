<template>
  <v-container>
    <h1 class="mb-4 text-h1">
      {{ $t('Profile') }}
    </h1>

    <v-row>
      <v-col order="1" order-md="2" sm="6" md="3" cols>
        <v-card class="pa-5 simple-nav" flat>
          <h3 class="mb-6">
            {{ $t('My settings') }}
          </h3>
          <p>
            <router-link :to="{name: 'profile'}">
              {{ $t('Basic information') }}
            </router-link>
          </p>
          <p>
            <router-link :to="{name: 'profile-password'}">
              {{ $t('Change password') }}
            </router-link>
          </p>
          <p v-if="showSubscriptions">
            <router-link :to="{name: 'profile-subscription'}">
              {{ $t('Manage subscription') }}
            </router-link>
          </p>
          <p v-if="showCompany">
            <router-link :to="{name: 'profile-company'}">
              {{ $t('Company info') }}
            </router-link>
          </p>
          <p v-if="showTeammates">
            <router-link :to="{name: 'profile-teammates'}">
              {{ $t('Manage teammates') }}
            </router-link>
          </p>
          <p v-if="showSwitchToBusinessAccount">
            <router-link :to="{name: 'switch-to-business'}">
              {{ $t('Switch to business account') }}
            </router-link>
          </p>

          <v-card v-if="enquiryInProgress" outlined>
            <v-card-text>
              <div class="d-flex align-center">
                <v-icon class="mr-1" v-text="icons.info" />
                {{ $t('Enquiry moderation in progress') }}
              </div>
            </v-card-text>
          </v-card>
          <v-card v-if="enquiryRejected" outlined>
            <v-card-text>
              <div class="d-flex align-center body-2 error--text">
                <v-icon class="mr-1" color="error" v-text="icons.warn" />
                {{ $t('Enquiry rejected') }}
              </div>
              <template v-if="enquiry.message">
                <p class="text-caption mt-3 mb-0">
                  {{ $t('Note from moderator') }}
                </p>
                <div>{{ enquiry.message }}</div>
              </template>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>

      <v-col order="2" order-md="1" md="9" cols>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import {mdiCloseOctagonOutline, mdiInformationOutline} from '@mdi/js'

export default {
  name: 'Profile',
  data () {
    return {
      icons: {
        info: mdiInformationOutline,
        warn: mdiCloseOctagonOutline,
      },
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      enquiry: state => state.profile.enquiries?.at(-1),
      isBusinessAccount: state => state.profile.accountType === 'business',
    }),
    showSubscriptions () {
      // return !this.profile.accountType
      return this.isBusinessAccount
    },
    showTeammates () {
      return this.isBusinessAccount && this.profile.role === 'manager'
    },
    showCompany () {
      return this.isBusinessAccount && this.profile.role === 'manager'
    },
    enquiryInProgress () {
      return this.enquiry?.status === 'SENT'
    },
    enquiryRejected () {
      return this.enquiry?.status === 'REJECTED'
    },
    showSwitchToBusinessAccount () {
      return !this.isBusinessAccount && this.profile.verified && !this.enquiryInProgress
    },
  },
}
</script>
