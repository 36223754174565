<template>
  <v-card class="pa-5" flat outlined>
    <v-form @submit.prevent>
      <v-row>
        <v-col>
          <v-text-field
            v-model="localProfile.name"
            :error-messages="invalidFeedback.name"
            :label="$t('Name')"
            disabled
            name="name"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="localProfile.email"
            :label="$t('Email')"
            disabled
            name="email"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="localProfile.currency"
            :error-messages="invalidFeedback.currency"
            :items="currencies"
            :label="$t('Currency')"
            name="currency"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-start justify-end flex-wrap">
          <v-btn
            :ripple="false"
            class="mb-3"
            color="primary"
            outlined
            rounded
            @click="$router.push({name: 'profile'})"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            :disabled="saving"
            :loading="saving"
            :ripple="false"
            class="ml-3"
            color="primary"
            elevation="0"
            rounded
            @click="submit"
          >
            {{ $t('Update Profile') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProfileBasicEdit',
  mixins: [error401handler],
  data () {
    return {
      saving: false,
      localProfile: {},
      invalidFeedback: {
        name: null,
        email: null,
        currency: null
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    ...mapGetters({
      currencies: 'getCurrencies'
    }),
    nameValidness () {
      return !!(this.localProfile.name &&
        this.localProfile.name.trim().length <= 191 &&
        !this.invalidFeedback.name)
    },
    formValid () {
      return this.nameValidness
    }
  },
  created () {
    this.localProfile = {...this.profile}
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show'
    }),
    ...mapActions(['updateProfile']),
    async submit () {
      this.saving = true
      try {
        await this.updateProfile({currency: this.localProfile.currency})
        this.showNotify({
          text: this.$t('Successfully updated profile'),
          color: 'success'
        })
        await this.$router.push({name: 'profile'})
      } catch (e) {
        if (!this.handleError(e) && e.response.status === 422) {
          this.invalidFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    }
  }
}
</script>
