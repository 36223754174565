<template>
  <v-card class="pa-5" flat outlined>
    <v-overlay :value="!profileLoaded" absolute opacity="0">
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="profileLoaded">
      <v-row>
        <v-col>
          <h2 class="text-h2">
            {{ $t('Registration of business account') }}
          </h2>
        </v-col>
      </v-row>

      <v-form ref="form" enctype="multipart/form-data" @submit.prevent="submit">
        <!-- Название -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="localAccount.details.name"
              :disabled="saving"
              :error-messages="invalidFeedback['details.name']"
              :label="$t('Company name')"
              outlined
            />
          </v-col>
        </v-row>

        <!-- ИНН, КПП, ОГРН -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="localAccount.details.ogrn"
              :disabled="saving"
              :error-messages="invalidFeedback['details.ogrn']"
              :label="$t('Company OGRN')"
              counter="13"
              outlined
              type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="localAccount.details.inn"
              :disabled="saving"
              :error-messages="invalidFeedback['details.inn']"
              :label="$t('Company INN')"
              counter="10"
              outlined
              type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="localAccount.details.kpp"
              :disabled="saving"
              :error-messages="invalidFeedback['details.kpp']"
              :label="$t('Company KPP')"
              counter="9"
              outlined
              type="number"
            />
          </v-col>
        </v-row>

        <!-- Директор -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="localAccount.details.position"
              :disabled="saving"
              :error-messages="invalidFeedback['details.position']"
              :label="$t('Director position title')"
              outlined
            />
          </v-col>
        </v-row>

        <!-- Файлы ЕГРЮЛ -->
        <v-row>
          <v-col>
            <h3 class="mb-3">
              {{ $t('FileInputLabelEgrul') }}
            </h3>
            <v-file-input
              v-model="localAccount.egrul"
              :disabled="saving"
              :error-messages="filesInvalidFeedback.egrul"
              :label="$t('Select files')"
              chips
              clearable
              multiple
              outlined
              truncate-length="64"
            />
            <div v-if="uploadedFiles.egrul.length" class="d-flex flex-column align-start">
              <v-chip
                v-for="file in uploadedFiles.egrul"
                :key="file.id"
                class="mb-1"
                close
                label
              >
                {{ file.filename }}
              </v-chip>
            </div>
          </v-col>
        </v-row>

        <!-- Файлы ИНН -->
        <v-row>
          <v-col>
            <h3 class="mb-3">
              {{ $t('FileInputLabelInn') }}
            </h3>
            <v-file-input
              v-model="localAccount.fns"
              :disabled="saving"
              :error-messages="filesInvalidFeedback.fns"
              :label="$t('Select files')"
              chips
              clearable
              multiple
              outlined
              truncate-length="64"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="plan in plans"
            :key="plan.id"
            class="d-flex align-stretch justify-start"
            cols="auto"
            xl="3"
            lg="4"
          >
            <v-card
              :class="{'bg-gradient': plan.id === localAccount.details.plan_id}"
              class="v-card-inner d-flex flex-column px-2 py-4"
              min-height="376"
              min-width="290"
              dark
              flat
            >
              <v-card-title>
                <div>
                  <span class="text-capitalize">{{ plan.title }}</span>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="d-flex align-baseline">
                  <div class="text-h3 text--primary mr-1">
                    {{ plan.price === '' ? $t('Custom') : formatPrice(plan) }}
                  </div>
                  <div :class="{'transparent--text': plan.price === ''}">
                    {{ $t('per_interval', {interval: plan.period_title}) }}
                  </div>
                </div>
                <div class="mt-6">
                  <div v-for="(str, i) in plan.description" :key="`desc-${plan.id}-${i}`" class="d-flex">
                    <v-icon color="white">
                      {{ icons.check }}
                    </v-icon>
                    <div class="px-1">
                      {{ str }}
                    </div>
                    <div v-if="i === 0">
                      <v-chip
                        :class="[plan.id === localAccount.details.plan_id ? 'black' : 'bg-gradient']"
                        class="px-2"
                        small
                      >
                        {{ plan.deposit_limit }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-spacer />
              <v-card-actions class="justify-center">
                <v-btn
                  v-if="localAccount.details.plan_id === plan.id"
                  :ripple="false"
                  color="white"
                  light
                  block
                  elevation="0"
                  rounded
                >
                  {{ $t('Chosen tariff') }}
                </v-btn>
                <v-btn
                  v-else
                  :ripple="false"
                  block
                  class="btn-dark-border-gradient"
                  dark
                  elevation="0"
                  rounded
                  @click="localAccount.details.plan_id = plan.id"
                >
                  {{ $t('Choose tariff') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="planError">
          <v-col class="error--text text-caption">
            {{ planError }}
          </v-col>
        </v-row>

        <!-- Кнопки -->
        <v-row>
          <v-col class="d-flex align-start justify-end flex-wrap">
            <v-btn
              :ripple="false"
              class="mb-3 btn-light-border-gradient"
              color="primary"
              outlined
              rounded
              @click="$router.push({name: 'profile'})"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              :disabled="saving"
              :loading="saving"
              :ripple="false"
              class="ml-3 btn-bg-gradient"
              color="primary"
              elevation="0"
              rounded
              @click="submit"
            >
              {{ $t('Send enquiry') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-dialog
        v-model="showSuccess"
        max-width="450"
        @click:outside="closeSuccessModal"
      >
        <v-card>
          <v-card-title>
            {{ $t('Enquiry sent') }}
          </v-card-title>

          <v-card-text>
            <p>
              Ваши данные в настоящий момент проходят модерацию.
              После ее завершения вы сможете получить доступ к&nbsp;функциям личного кабинета.
              Проверка данных профиля осуществляется в течение 24 часов.
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              :ripple="false"
              color="primary"
              text
              @click="closeSuccessModal"
            >
              {{ $t('Ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {mdiCheck} from '@mdi/js'
import error401handler from '@/mixins/error-401-handler'
import {makeFormData} from '@/util/util'

export default {
  name: 'ProfileBusinessAccountCreate',
  mixins: [error401handler],
  data () {
    return {
      saving: false,
      localAccount: {
        type: 'new_company',
        details: {
          name: '',
          ogrn: '',
          inn: '',
          kpp: '',
          position: 'Директор',
          plan_id: '',
        },
        egrul: [],
        fns: [],
      },
      uploadedFiles: {
        egrul: [],
        fns: [],
      },
      invalidFeedback: {},
      showSuccess: false,
      icons: {
        check: mdiCheck,
      },
    }
  },
  mounted () {
    if (this.profileLoaded) this.initialLoad()
  },
  watch: {
    profileLoaded (val) {
      if (val) this.initialLoad()
    },
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      profileLoaded: state => state.loaders.profile.status,
      currencies: state => state.config.currencies,
      enquiry: state => state.profile.enquiries?.at(-1),
      plans: state => state.subscriptionsBusiness.plans,
    }),
    filesInvalidFeedback () {
      let errors = {egrul: [], fns: []}
      for (let [key, msg] of Object.entries(this.invalidFeedback)) {
        if (key.startsWith('egrul')) {
          errors.egrul.push(...msg)
        }
        if (key.startsWith('fns')) {
          errors.fns.push(...msg)
        }
      }
      return errors
    },
    planError () {
      if (this.invalidFeedback['details.plan_id']) {
        if (this.invalidFeedback['details.plan_id'][0] === 'Поле обязательно для заполнения.') {
          return this.$t('Please choose tariff')
        } else {
          return this.invalidFeedback['details.plan_id'].join(' ')
        }
      }
      return false
    },
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show',
    }),
    ...mapActions({
      loadProfile: 'loadProfile',
      switchToBusinessAccount: 'switchToBusinessAccount',
      getEnquiryDetails: 'getEnquiryDetails',
      loadPlans: 'subscriptionsBusiness/ssLoadPlans',
    }),
    async initialLoad () {
      if (this.enquiry?.status === 'REJECTED') {
        let {data: {data}} = await this.getEnquiryDetails(this.enquiry.id)
        this.localAccount.type = data.type
        this.localAccount.details = {plan_id: '', ...data.details}
        // TODO придумать, как старые файлы прикреплять к новой заявке
        // for (let file of data.documents) {
        //   this.uploadedFiles[file.type].push(file)
        // }
      } else if (this.enquiry?.status === 'SENT') {
        return this.$router.push({name: 'profile'})
      }
      await this.loadPlans()
    },
    formatPrice (plan) {
      let price = plan.price.toString().replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1 `)
      let currencySymbol = this.currencies[plan.currency].symbol
      return `${price} ${currencySymbol}`
    },
    async submit () {
      this.saving = true
      try {
        await this.switchToBusinessAccount(makeFormData(this.localAccount))
        this.showNotify({
          text: this.$t('Enquiry sent'),
          color: 'success',
        })
        this.showSuccess = true
        await this.loadProfile()
      } catch (e) {
        if (!this.handleError(e) && e.response.status === 422) {
          this.invalidFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    },
    closeSuccessModal () {
      this.showSuccess = false
      return this.$router.push({name: 'profile'})
    },
  },
}
</script>
