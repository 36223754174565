<template>
  <div>
    <template v-if="profileLoaded">
      <payment-plans-business v-if="isBusinessAccount" />
      <payment-plans v-else />
    </template>

    <!--<payment-saved-methods />-->
  </div>
</template>

<script>
import {mapState} from 'vuex'
import PaymentPlans from '@/components/PaymentPlans'
import PaymentPlansBusiness from '@/components/PaymentPlansBusiness'
import PaymentSavedMethods from '@/components/PaymentSavedMethods'

export default {
  name: 'ProfileSubscription',
  components: {PaymentPlansBusiness, PaymentPlans, PaymentSavedMethods},
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      isBusinessAccount: state => state.profile.accountType === 'business',
    }),
  },
}
</script>
